import { blogAppDefId, blogCommentsAppDefId } from '../../constants/apps';
import { BLOG_COMMENTS_WIDGET_ID, RELATED_POSTS_WIDGET_ID } from '../../constants/widgets';

export function createCommentsDefinition({ applicationId, positionY }) {
  return {
    layout: {
      x: 0,
      y: positionY ?? 0,
      width: 980,
      height: 1048,
    },
    type: 'Container',
    skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
    componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
    data: {
      widgetId: BLOG_COMMENTS_WIDGET_ID,
      applicationId: `${applicationId}`,
      appDefinitionId: blogCommentsAppDefId,
      type: 'TPAWidget',
    },
    mobileHints: {
      type: 'MobileHints',
      shouldBeForceConverted: true,
    },
    style: 'tpaw0',
  };
}

export function createRelatedPostsDefinition({ applicationId, positionY }) {
  return {
    layout: {
      x: 0,
      y: positionY ?? 0,
      width: 980,
      height: 380,
    },
    type: 'Container',
    skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
    componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
    data: {
      widgetId: RELATED_POSTS_WIDGET_ID,
      applicationId: `${applicationId}`,
      appDefinitionId: blogAppDefId,
      type: 'TPAWidget',
    },
    mobileHints: {
      type: 'MobileHints',
      shouldBeForceConverted: true,
    },
    style: 'tpaw0',
  };
}
